import { useState } from "react";
import "./App.scss";
import useInterval from "./hooks/useInterval";

interface Highscore {
  name: string;
  score: number | null;
}

interface Highscores {
  pacMan: Highscore[];
  ghostbusters: Highscore[];
  pong: Highscore[];
}

const refreshRate = 10000; // refresh highscores every 10s

const App = () => {
  const [highscores, setHighscores] = useState<Highscores>();
  const [delay, setDelay] = useState<number>(1000);

  useInterval(async () => {
    const response = await fetch("/api/get-highscores");
    const newHighscores = await response.json();
    setHighscores(newHighscores);
    setDelay(refreshRate);
  }, delay);

  return (
    <main>
      <div className="c-footer-background">
        <div className="c-footer-nav__anim-overlay">
          <div className="c-footer-nav__anim-grid">
            <div className="c-footer-nav__anim-fade"></div>
            <div className="c-footer-nav__anim-lines"></div>
          </div>
        </div>
        <div className="c-footer-background__start">Press Start Up</div>
      </div>

      <div className="c-header">
        <img src="/arcade-logo.svg" alt="" height="320" />
      </div>

      <div className="c-core">
        {highscores && (
          <Table logo={"/logo-pacman.svg"} highscores={highscores.pacMan} />
        )}
        {highscores && (
          <Table
            logo={"/logo-ghostbusters.svg"}
            highscores={highscores.ghostbusters}
          />
        )}
        {highscores && (
          <Table logo={"/logo-pong.svg"} highscores={highscores.pong} />
        )}
      </div>
    </main>
  );
};

const Table = ({
  logo,
  highscores,
}: {
  logo: string;
  highscores: Highscore[];
}) => {
  if (highscores.length < 9) {
    // pad highscores
    const padding = Array.from(Array(9 - highscores.length).keys()).map(i => ({name: "", score: null}) as Highscore)
    highscores.push(...padding);
  }
  return (
    <div className="c-table">
      <div className="c-table__title">
        <img src={logo} alt="" height="40" />
      </div>

      <div className="c-table__head">
        <div>#</div>
        <div>Name</div>
        <div>Score</div>
      </div>

      <div className="c-table__body">
        {highscores
          .slice(0, 9)
          .map((hs, i) => (
            <div key={i} className="c-table__row">
              <div>{getNumberWithOrdinal(i + 1)}</div>
              <div>{!!hs.name ? hs.name : ""}</div>
              <div>{!!hs.score ? hs.score.toLocaleString("en-US") : ""}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

const getNumberWithOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export default App;
